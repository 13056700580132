<template>
<!-- 订单管理 -->
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <div class="flexAC" style="margin: 16px 0px;">
        <el-input v-model="indentid"  placeholder="请输入订单编号/手机号/用户名" class="inputSearch"></el-input>
        <el-button class="btnSearch"  @click="handleSearch">搜索</el-button>
        <el-button class="btnReset"  @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="orderList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="序号"
            type="index"
            width="100">
          </el-table-column>
          <el-table-column
            label="订单编号"
            prop="indentid"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="订单类型"
            prop="indentype">
            <template slot-scope="scope">
              <span v-if="scope.row.indentype == 1">平台订单</span>
              <span v-if="scope.row.indentype == 2">平台充值</span>
              <span v-if="scope.row.indentype == 3">平台次卡</span>
            </template>
          </el-table-column>
          <el-table-column
            label="用户名称"
            prop="name">
          </el-table-column>
          <el-table-column
            label="手机号码"
            prop="iphone">
          </el-table-column>
          <el-table-column
            label="合计金额"
            prop="paymoney">
          </el-table-column>
          <el-table-column
            label="订单状态"
            prop="indentstatevalue">
          </el-table-column>
          <el-table-column
            label="支付方式"
            prop="sysvalue">
          </el-table-column>
          <el-table-column
            label="服务门店"
            prop="shopname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            width="100"
            label="操作">
            <template slot-scope="scope" v-if="roleBtn[0].power">
              <el-button type="text" size="small" @click="showDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
    <!-- 详情 -->
    <div class="detailForm" v-if="showForm" >
      <BillDetail :editId="editId"  @detailBack="detailBack"></BillDetail>
    </div>
  </div>
</template>

<script>
import BillDetail from '@/components/recepion/billDetail'
import { indentGet } from '@/api/order.js'
export default {
  components: {
    BillDetail
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      indentid: '',
      orderList: [],
      showForm: false,
      editId: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        indentid: this.indentid,
        page_index: this.page,
        page_size: this.size
      }
      indentGet(params).then(res => {
        if (res.status === 1) {
          this.orderList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.orderList = []
          this.total = 0
        }
      })
    },
    // 详情
    showDetail (row) {
      this.showForm = true
      this.editId = row.indentid
    },
    detailBack () {
      this.showForm = false
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.indentid = ''
      this.init()
    },
    pageChange (page) {
      this.page = page
      this.init()
    }
  }
}
</script>

<style>

</style>
